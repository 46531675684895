// Framework Imports
import React, { useState } from 'react';
import InViewMonitor from 'react-inview-monitor';
import { TypeAnimation } from 'react-type-animation';
// Style Imports
import './videoTyping.css';
// Component Imports
import MainSection from 'Layout/MainSection';
import MainContent from 'Layout/MainContent';
import Video from 'Components/Video';
// Image Imports
import dosVidPreview from 'data/images/video/dos-trailer.png';
import dosVidThumbnail from 'data/images/video/dos-trailer.thumb.jpg';

// Define text here
const firstIntroParagraph = 'The first ship went missing.';
const secondIntroParagraph = "Now it's your turn.";
const thirdIntroParagraph =
  'When The Baroness submarine goes dark while investigating a massive vibration deep in the ocean, Abe Douglas, the commander who assembled its crew, makes it his responsibility to find out what happened.';
const fourthIntroParagraph =
  "As he plunges into the ocean, he has no idea that what he'll uncover will not only threaten the lives of his crew, but his own sanity.";

const VideoTyping = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const startPlaying = () => {
    if (!isFinished) {
      setIsPlaying(true);
    }
  };

  return (
    <MainSection id="trailer" fadeBg bottomDivider>
      <Video
        id="Un2Fkvfi7_k"
        previewImage={dosVidPreview}
        placeholderImage={dosVidThumbnail}
        title="Depths of Sanity - PC Launch Trailer Nov 2, 2022 - Underwater Metroidvania"
        autoplay
      />
      <InViewMonitor onInView={startPlaying}>
        <MainContent retroFont>
          <div className="VideoTyping-wrapper">
            <p
              className={`VideoTyping-content${isFinished ? '' : ' u-hidden'}`}
            >
              {`${firstIntroParagraph}\n\n${secondIntroParagraph}\n\n${thirdIntroParagraph}\n\n${fourthIntroParagraph}`}
            </p>
            {isPlaying && !isFinished && (
              <TypeAnimation
                sequence={[
                  `${firstIntroParagraph}`,
                  500,
                  `${firstIntroParagraph}\n\n${secondIntroParagraph}`,
                  1000,
                  `${firstIntroParagraph}\n\n${secondIntroParagraph}\n\n${thirdIntroParagraph}`,
                  500,
                  `${firstIntroParagraph}\n\n${secondIntroParagraph}\n\n${thirdIntroParagraph}\n\n${fourthIntroParagraph}`,
                  () => {
                    setIsFinished(true);
                  },
                ]}
                cursor
                style={{
                  whiteSpace: 'pre-line',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                }}
              />
            )}
          </div>
        </MainContent>
      </InViewMonitor>
    </MainSection>
  );
};

export default VideoTyping;
